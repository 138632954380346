import React, {useState} from 'react';
import './App.css';

function App() {

    const [data, setData] = useState("INITIAL")
    const getRequest = () => {
        fetch(`https://pwaserver.msinacatalog.com/`)
            .then(res => res.text())
            .then(data => setData(data))
            .catch(() => {
                setData("ERROR")
            })
    }

    const notifyWebView = () => {
        window.webkit.add.postMessage("salute", "hola")
    }

    return (
        <div className="App">
            <button onClick={getRequest}>Click me!</button>
            <button onClick={notifyWebView}>Click me!</button>
            <p>{data}</p>
            <p>PWA VERSION</p>
        </div>
    );
}

export default App;
